<template>
  <base-info-card title="Produk">
    <a
      v-for="product in posts"
      :key="product.web_product_id"
      class="mb-2 white--text body-1 d-block text-none"
      v-html="`&bull; ${product.web_product_name}`"
    />
    <!-- @click="Detail(product.web_product_id)" -->
    <v-snackbar
        v-model="snackbar.value"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn
          color="fourth"
          text
          v-bind="attrs"
          @click="snackbar.value = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
  </base-info-card>
</template>

<script>
  export default {
    name: 'ProductFeatures',

    data: () => ({
      posts: [],
      snackbar: {
        value: false,
        text: ''
      }
    }),
    created () {
      this.List()
    },
    methods: {
      List () {
        this.posts = [
                      {'web_product_id':'0101','web_product_name':'Penjaminan Kredit Multiguna','web_product_src_url':''},
                      // {'web_product_id':'0102','web_product_name':'Penjaminan Kredit Kepemilikan Rumah','web_product_src_url':''},
                      {'web_product_id':'0103','web_product_name':'Penjaminan Kredit Mikro','web_product_src_url':''},
                      {'web_product_id':'0104','web_product_name':'Penjaminan Kredit Umum','web_product_src_url':''},
                      // {'web_product_id':'0105','web_product_name':'Penjaminan Konstruksi atau Pengadaan Barang dan Jasa','web_product_src_url':''},
                      // {'web_product_id':'0106','web_product_name':'Penjaminan Supply Chain Financing','web_product_src_url':''},
                      // {'web_product_id':'0201','web_product_name':'Surety Bond','web_product_src_url':''},
                      // {'web_product_id':'0202','web_product_name':'Kontra Bank Garansi','web_product_src_url':''},
                      // {'web_product_id':'0203','web_product_name':'Custom Bond','web_product_src_url':''}
                      ]
      },
      Detail (url) {
        this.$router.push({name: 'produk-detail', params: { url: url }})
      },
    }
  }
</script>